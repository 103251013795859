import * as React from "react";
import Button from "../components/button/Button";

const NotFoundPage = () => {
    return (
        <section className="dark-bg-1 flex-min-height-box">
            <div className="flex-min-height-inner" style={{ flexDirection: "column", justifyContent: "center", }}>
                <h2 className="large-title text-height-12 red-color text-center">
                  404
                </h2>
                <div className="twelve-columns text-center text-color-4 top-margin-10 bottom-margin-20" style={{ fontSize: "18px", }}>
                    The page you're looking for doesn't exist.
                </div>
                <Button href="/">Go home</Button>
            </div>
        </section>
    );
};

export default NotFoundPage;
